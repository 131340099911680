@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-Regular.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 400;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-RegularItalic.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-Medium.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 500;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-MediumItalic.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-Semibold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 600;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-SemiboldItalic.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 700;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-Bold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 700;
  src: url('../../../libs/web-components/src/lib/styles/fonts/Graphik-BoldItalic.otf')
    format('opentype');
}

.XqTypography-h1 {
  font-weight: 700;
  font-size: var(--twenty-pixels-font-size);
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}
