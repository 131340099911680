html {
  font-family: 'Graphik', sans-serif;

  body {
    background: var(--application-background-color);
    font-size: 0.875rem;

    figcaption {
      font-size: 12px;
    }

    [data-selectable-paragraph] {
      background-color: var(--panache);
      padding: 8px;
      border-radius: 3px;
      margin-bottom: 8px;
    }
  }
}

.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}
