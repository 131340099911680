:root {
  --green-primary: #3eb650;
  --green-secondary: #45996e;
  --moss-green: #a0d1a7;
  --panache: #ebf7ed;

  --orange-primary: #f16331;
  --hot-cinnamon: #e15321;

  --blue-primary: #0121ff;
  --blue-secondary: #0080ff;
  --royal-blue: #2c5eec;
  --hoki: #658996;
  --link-water: #dae7f2;
  --mariner: #3367d6;
  --cornflower-blue: #4285f4;
  --smalt-blue: #547c89;

  --yellow-primary: #d9e021;

  --grey-dark: #1d1f2d;
  --grey-semi-dark: #808080;
  --grey-semi-purple: #9888a0;
  --grey-medium: #9b9b9b;
  --grey-light: #d5d5d5;
  --alto: #d9d9d9;
  --gray: #898989;
  --charade: #272833;
  --athens-gray: #e7e7ed;
  --alabaster: #fbfbfb;
  --mine-shaft: #2d2d2d;
  --mercury: #e8e7e7;
  --silver: #c4c4c4;
  --gray-suit: #c5c1c8;
  --river-bed: #414d54;
  --cod-gray: #1a1a1a;

  --shark: #1e1f20;

  --haiti: #220f2d;
  --blackcurrant: #3d2c46;
  --mamba: #9888a0;
  --mulled-wine: #5b4269;

  --white: #fff;
  --black: #000;

  --purple-medium: #a94de0;

  --grey-text: rgba(255, 255, 255, 0.5);
  --nav-icon-background-width: 40px;
  --nav-icon-background-color: var(--blackcurrant);
  --teacher-nav-width: 96px;
  --separator-width: 32px;
  --teacher-nav-background-color: var(--haiti);
  --student-nav-background-color: var(--haiti);
  --notifications-icon-background-color: var(--green-primary);
  --dropdown-background-color: var(--silver);
  --application-background-color: var(--alabaster);
  --top-bar-height: 56px;
  --teacher-chat-headers: 245px;
  --chat-header-height: 70px;
  --chat-header-input-height: 300px;
  --chat-input-height: 85px;
  --feed-filter-height: 95px;
  --feed-editor-height: 147px;
  --background-active-color: var(--green-primary);
  --classroom-hover-active-color: rgba(255, 255, 255, 1);
  --teacher-feed-header-tabs-height: 97px;
  --filter-feed-height: 95px;
  --feed-input-field-height: 147px;

  --active-channel-item-color: rgba(255, 255, 255, 0.74);
  --inactive-channel-items-color: rgba(0, 0, 0, 0.38);
  --channel-separator-border: 0.3px solid #eeeeee;
  --new-channel-message-color: rgba(0, 0, 0, 0.87);
  --divider-color: #e6e6e6;
  --teacher-name-color: rgba(0, 0, 0, 0.9);

  --channel-search-container-border: 1px solid #d5d5d5;
  --title-border-bottom: 1px solid #d4d4d4;
  --twenty-pixels-font-size: 20px;
  --light-grayish-violet: #ecebee;
  --input-field-value: rgb(117, 117, 117);

  --text-area-toolbar-background-color: rgba(0, 0, 0, 0.04);
  --text-area-border-color: rgb(33, 33, 33);
  --text-area-toolbar-border-color: lightgrey;

  --wizard-guide-body-text-color: #1e1f2099;
  --disabled-label-background: #d4d4d4;
}
